import React, {  useState } from "react";

import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../Partials/Layouts/Forms/Form";
import { STATE_API } from "../../../Utilities/APIs/APIs";

function CreateAddress({ setShowCreateForm, setShowModal, triggerFetch,storeId }) {
  const axiosInstance = useAxiosInstance()

  const [ name, setName] = useState("");


  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = {name}
    const response = await axiosInstance.post(STATE_API, itemData)
    if (response.data) {
        setShowCreateForm(false);
        setShowModal(false);
        triggerFetch()
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <ShortTextInput
        label={`State`}
        value={name}
        placeholder={`Enter State`}
        setState={setName}
      />
     
      <FormSubmitButton text="Create State" />
    </Form>
  );
}

export default CreateAddress;
