import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard";
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList";
import { GiClothes, GiConverseShoe } from "react-icons/gi";
function ProductScreen() {
  return (
    <NavCardList numOfCards="five">
      <NavCard cardName={`Sneakers`} navCardLink={`/productScreen/sneaker`}>
        <GiConverseShoe />
      </NavCard>
      <NavCard cardName={`Apparel`} navCardLink={`/productScreen/apparel`}>
        <GiClothes />
      </NavCard>
    </NavCardList>
  );
}

export default ProductScreen;
