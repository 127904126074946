import React, { useContext, useState } from "react";
import Form from "../../../../Partials/Layouts/Forms/Form";
import EmailInput from "../../../../Partials/Layouts/Forms/FormInputs/EmailInput/EmailInput";
import PasswordInput from "../../../../Partials/Layouts/Forms/FormInputs/PasswordInput/PasswordInput";
import AppContext from "../../../../../AppContext/AppContext";
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";

export default function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { login } = useContext(AppContext);

  function handleSubmit(e) {
    e.preventDefault();

    const employeeData = {
      email,
      password,
    };

    login(employeeData);
  }

  return (
    <Form onSubmit={handleSubmit}>
      <EmailInput
        label={"Email"}
        value={email}
        placeholder={"Please Enter your Email"}
        setState={setEmail}
      />
      <PasswordInput
        label={"Password"}
        value={password}
        placeholder={"Please Enter your Password"}
        setState={setPassword}
      />
      <FormSubmitButton text={"Sign In"} />
    </Form>
  );
}
