import React from "react";
import Modal from "../../../../../Partials/Elements/Modal/Modal";
import ResendInvite from "./components/ResendInvite";
import CreateInvite from "./components/CreateInvite";
import DeleteInvite from "./components/DeleteInvite";

export default function InviteEmployeeModal({
  showModal,
  handleClose,
  showCreateForm,
  showDeleteSection,
  showResendSection,
  employee,
  setShowCreateForm,
  setShowModal,
  targetID,
  triggerFetch,
  setShowDeleteSection,
  setShowResendSection,
}) {
  return (
    <>
      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Invite`) ||
            (showDeleteSection && `Delete Invite`) ||
            (showResendSection && ` Resend Invite`)
          }
        >
          {showCreateForm && (
            <CreateInvite
              employee={employee}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showResendSection && (
            <ResendInvite
              employee={employee}
              setShowResendSection={setShowResendSection}
              setShowModal={setShowModal}
              targetID={targetID}
            />
          )}
          {showDeleteSection && (
            <DeleteInvite
              employee={employee}
              setShowDeleteSection={setShowDeleteSection}
              setShowModal={setShowModal}
              targetID={targetID}
              triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}
    </>
  );
}
