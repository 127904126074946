import { useContext, useEffect, useState } from "react";

import AppContext from "../../../../AppContext/AppContext";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import {
  ALL_EMPLOYEE_INVITES_API,
  ALL_EMPLOYESS_API,
} from "../../../../Utilities/APIs/APIs";
import InviteEmployeeHeader from "./components/InviteEmployeeHeader";
import InviteEmployeeTable from "./components/InviteEmployeeTable";
import InviteEmployeeModal from "./components/InviteEmployeeModal/InviteEmployeeModal";

import "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDBoilerPlate.css";
import "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CRUDHeader.css";
import "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ActionButtonsCell/ActionButtonsCell.css";
import "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDViewBoilerPlate/CRUDViewBoilerPlate.css";

function InviteEmployees() {
  const [invitedEmployees, setInvitedEmployees] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showResendSection, setShowResendSection] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);

  const { employee } = useContext(AppContext);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetInvitedEmployees() {
      const { data } = await axiosInstance.get(ALL_EMPLOYEE_INVITES_API);
      setInvitedEmployees(data);
    }

    fetchAndSetInvitedEmployees();

    async function fetchAndSetEmployees() {
      const { data } = await axiosInstance.get(ALL_EMPLOYESS_API);
      setEmployees(data);
    }

    fetchAndSetEmployees();
  }, [axiosInstance, toggleFetch]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowDeleteSection(false);
    setShowResendSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <InviteEmployeeHeader
          invitedEmployees={invitedEmployees}
          setShowModal={setShowModal}
          setShowCreateForm={setShowCreateForm}
        />
        <InviteEmployeeTable
          invitedEmployees={invitedEmployees}
          employees={employees}
          setShowModal={setShowModal}
          setShowDeleteSection={setShowDeleteSection}
          setShowResendSection={setShowResendSection}
          setTargetID={setTargetID}
        />
      </div>
      <InviteEmployeeModal
        employee={employee}
        showModal={showModal}
        handleClose={handleClose}
        showCreateForm={showCreateForm}
        showDeleteSection={showDeleteSection}
        showResendSection={showResendSection}
        triggerFetch={triggerFetch}
        targetID={targetID}
        setShowCreateForm={setShowCreateForm}
        setShowModal={setShowModal}
        setShowDeleteSection={setShowDeleteSection}
        setShowResendSection={setShowResendSection}
      />
    </>
  );
}

export default InviteEmployees;
