
import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import { STREET_API } from "../../../../Utilities/APIs/APIs";


function UpdateStreet({ setShowUpdateForm, setShowModal, triggerFetch,targetID,cityId }) {
  const axiosInstance = useAxiosInstance()
  const [name, setName] = useState("");

  useEffect(() => {
    async function fetchAndsetStoreInfo() {
      const { data } = await axiosInstance.get(STREET_API + targetID);
      setName(data.name);   
    }
    fetchAndsetStoreInfo();
  }, [targetID, axiosInstance,]);

  async function handleSubmit(e) {
    e.preventDefault();
    const itemData = new FormData();

    itemData.append("name",name)
    itemData.append("city",cityId)

    const response = await axiosInstance.patch(STREET_API + targetID, itemData)
    if (response.data) {
        setShowUpdateForm(false);
        setShowModal(false);
        triggerFetch()
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`City`}
        value={name}
        placeholder={`Enter State`}
        setState={setName}
      />
     
      <FormSubmitButton text="Create State" />
    </Form>
  );
}

export default UpdateStreet;
