import {FiPenTool} from 'react-icons/fi'
import CRUDButton from '../CRUDButton';
import { FiEdit } from "react-icons/fi"; 
import './EditButton.css';
import { useLocation } from 'react-router-dom';

function EditButton({setShowModal, setShowUpdateForm, setTargetID, targetID}) {

  const location = useLocation();
  // console.log('Location', location);
  return (
    <CRUDButton
    dataTip='Edit'
    dataFor='Edit Item'
      handleClick={() => {
        setShowModal(true)
        setShowUpdateForm(true)
        setTargetID(targetID)
      }}
    >
      {
        location.pathname === "/address" ?
        <FiEdit />
        :
        <FiPenTool />
      }
    </CRUDButton>
  )
}

export default EditButton