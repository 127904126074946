import { useEffect, useState } from "react";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";
import {
  ALL_SELLER_API,
  PAYMENT_TO_SELLER,
} from "../../../Utilities/APIs/APIs";
import Form from "../../Partials/Layouts/Forms/Form";
import NumberInput from "../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import SelectInput from "../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import DateInput from "../../Partials/Layouts/Forms/FormInputs/DateInput/DateInput";

function CreatePayment({ setShowCreateForm, setShowModal, triggerFetch }) {
  const axiosInstance = useAxiosInstance();


  const [sellers, setSellers] = useState([]);
  const [seller, setSeller] = useState("");
  const [amount, setAmount] = useState(0);
  const [dateOfPayment, setDateOfPayment] = useState("");

  useEffect(() => {
    async function fetchAndSetSellers() {
      const { data } = await axiosInstance.get(ALL_SELLER_API);
      setSellers(data);
    }
    fetchAndSetSellers();
  }, [axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = { seller,amount,dateOfPayment };

    const response = await axiosInstance.post(PAYMENT_TO_SELLER, itemData);

    if (response.data) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }
  return (
    <Form onSubmit={handleSubmit} hasImage>

      <SelectInput label={"Seller"} value={seller} setState={setSeller}>
        <SelectOption optionValue="" optionText="Select Seller" />
        {sellers?.map((seller) => (
          <SelectOption
            optionValue={seller._id}
            key={seller._id}
            optionText={seller?.name}
          />
        ))}
      </SelectInput>
      <NumberInput
      label={"Amount"}
      setState={setAmount}
      placeholder={"Enter Amount"}
      value={amount}
      />

      <DateInput
        label={`Date Of Payment`}
        value={dateOfPayment}
        placeholder={`Enter Date of Payment`}
        setState={setDateOfPayment}
      />

      <FormSubmitButton text="Create Payment" />
    </Form>
  );
}

export default CreatePayment;
