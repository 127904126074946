import React from "react";
import CRUDTable from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ImageCell from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell";
import ShortTextCell from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ArchiveButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ArchiveButton/ArchiveButton";

export default function EmployeesTable({
  employee,
  employees,
  setShowModal,
  setShowDeleteSection,
  setTargetID,
  setArchive,
}) {
  return (
    <CRUDTable>
      <CRUDTableHeader>
        <CRUDth th="Image" />
        <CRUDth th="Name" />
        <CRUDth th="Email Address" />
        <CRUDth th="Level" />
        {employee.level === "admin" && <CRUDth th="Actions" />}
      </CRUDTableHeader>
      <tbody>
        {employees &&
          employees?.map((e) => (
            <CRUDTableRow key={e._id}>
              <ImageCell imgSrc={e.dp} imgAlt={e.name} />
              <ShortTextCell text={e.name} />
              <ShortTextCell text={e.email} />
              <ShortTextCell text={e.level} />

              {employee.level === "admin" ? (
                <td className="action_button_cell">
                  <ArchiveButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={e._id}
                    isArchive={e.isArchive}
                    setTargetID={setTargetID}
                    setArchive={setArchive}
                  />
                </td>
              ) : (
                ""
              )}
            </CRUDTableRow>
          ))}
      </tbody>
    </CRUDTable>
  );
}
