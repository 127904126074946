import React from "react";
import Modal from "../../../../Partials/Elements/Modal/Modal";
import ArchiveItem from "../../../../Partials/Layouts/ArchiveItem/ArchiveItem";

export default function EmployeesModal({
  showModal,
  handleClose,
  targetID,
  triggerFetch,
  showDeleteSection,
  archive,
  setShowModal,
  setShowDeleteSection,
}) {
  return (
    <>
      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={showDeleteSection && `Delete Invite`}
        >
          {showDeleteSection && (
            <ArchiveItem
              api={"archiveEmployee/"}
              targetID={targetID}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
              isArchive={archive}
            />
          )}
        </Modal>
      )}
    </>
  );
}
