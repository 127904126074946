import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";


function TotalSeller({sellers}) {
  // totalSellers
  // const [totalSellers,setTotalSellers] = useState("")
  // const axiosInstance = useAxiosInstance()
  // useEffect(() => {
  //       async function fetchAndSetTotalSellers() {  
  //         const { data } = await axiosInstance.get("seller/totalSellers");
  //         setTotalSellers(data)
  //       }
  //       fetchAndSetTotalSellers();
  //     }, [axiosInstance]);

return (
  <div className="card">
    <p>Total Seller: </p>
    {sellers && <h3>{sellers}</h3>}
  </div>
)
   
  }
  
  export default TotalSeller

