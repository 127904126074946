import ReactDOM from "react-dom/client";
import App from "./Components/App";
import { AppContextProvider } from "./AppContext/AppContext";

import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AppContextProvider>
    <App />
  </AppContextProvider>
);
