import React from "react";
import CreateButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";

export default function InviteEmployeeHeader({
  invitedEmployees,
  setShowModal,
  setShowCreateForm,
}) {
  return (
    <div className="body_header">
      <h1 className="heading">{`Invited Employees (${invitedEmployees?.length} in total)`}</h1>
      <CreateButton
        screenTopicSingular="Employee Invite"
        setShowModal={setShowModal}
        setShowCreateForm={setShowCreateForm}
      />
    </div>
  );
}
