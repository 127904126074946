import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import { Product_API } from "../../../../../Utilities/APIs/APIs";

function ViewProduct({ targetID }) {
  const [product, setProduct] = useState({});
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetProduct() {
      const { data } = await axiosInstance.get(Product_API + targetID);
      console.log(data);
      setProduct(data);
    }
    fetchAndSetProduct();
  }, [targetID, axiosInstance]);
  return (
    <div className="crud_view_content">
      <>
        <h1>Product Name</h1>
        <p>{product?.name}</p>

        <h1>Card Image</h1>
        <figure style={{ width: "400px", height: "100px" }}>
          <img
            src={process.env.REACT_APP_SPACES_URL + product?.cardImage}
            alt="product"
            style={{
              objectFit: "contain",
              width: "100%",
              height: "100%",
            }}
          />
        </figure>
      </>
    </div>
  );
}

export default ViewProduct;
