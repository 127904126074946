import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import "./SellerPayments.css";
import TotalOrderPayments from './TotalOrderPayments';
import TotalPaidPayment from './TotalPaidPayment';
import TotalPayablePayment from './TotalPayablePayment';
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance';
import { RiBankCardLine } from 'react-icons/ri';
import CreatePayment from '../../../Payment/CreatePayment';
import Modal from '../../../../Partials/Elements/Modal/Modal';
import CreateSellerPayment from './CreateSellerPayment';

function SellerPayments() {
    const { sellerId } = useParams();
    const axiosInstance = useAxiosInstance();
    const [totalOrderValue, setTotalOrderValue] = useState("");
    const [totalPaidPayment, setTotalPaidPayment] = useState("");
    const [totalPayablePayment, setTotalPayablePayment] = useState("");
    const [showModal, setShowModal] = useState(false)
    const [toggleFetch, setToggleFetch] = useState(false)

    useEffect(() => {
        async function fetchData() {
            try {
                const { data: totalOrder } = await axiosInstance.get(`seller/totalOrderValue/${sellerId}`);
                const { data: totalPaid } = await axiosInstance.get(`seller/getSingleSellerTotalPaidPayment/${sellerId}`);
                setTotalOrderValue(totalOrder);
                setTotalPaidPayment(totalPaid);
            } catch (error) {
                // Handle error
                console.error('Error fetching data:', error);
            }
        }
        fetchData();
    }, [axiosInstance, sellerId, toggleFetch]);

    useEffect(() => {
        if (totalOrderValue !== "" && totalPaidPayment !== "") {
            const payable = totalOrderValue.totalAmount - totalPaidPayment.totalAmount;
            setTotalPayablePayment(payable);
        }
    }, [totalOrderValue, totalPaidPayment, toggleFetch]);

    function handleClose() {
        setShowModal(false);
    }

    function triggerFetch() {
        setToggleFetch((prevState) => !prevState);
    }

    return (
        <>
            <div className="dashboard_crud_holder">
                <div className="dashboard">
                    <TotalOrderPayments totalOrderValue={totalOrderValue} />
                    <TotalPaidPayment totalPaidPayment={totalPaidPayment} />
                    <TotalPayablePayment totalPayablePayment={totalPayablePayment} />

                </div>



                <div className='pay_btn_container'>
                    <button className='pay_btn' onClick={()=>setShowModal(true)}><RiBankCardLine /> Make Payment</button>
                </div>
            </div>

            {showModal && (
                <Modal
                    handleClose={handleClose}
                    modalHeading={`Create Payment`}
                >
                    <CreateSellerPayment
                        sellerId={sellerId}
                        setShowModal={setShowModal}
                        triggerFetch={triggerFetch}
                    />

                </Modal>
            )}
        </>

    );
}

export default SellerPayments;
