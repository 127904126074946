import { useEffect, useState } from "react";
import SellerCardInfo from "./SellerCardInfo/SellerCardInfo";

import useAxiosInstance from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import { SINGLE_SELLER_API } from "../../../../../../../Utilities/APIs/APIs";

import "./ViewSeller.css";

function ViewSeller({ targetID, employee }) {
  const [seller, setSeller] = useState(null);
  const [cardInfo, setCardInfo] = useState(null);

  const [activeTab, setActiveTab] = useState(1);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetSeller() {
      const { data } = await axiosInstance.get(SINGLE_SELLER_API + targetID);
      setSeller(data.seller);
      setCardInfo(data.cardInfo);
    }
    fetchAndSetSeller();
  }, [axiosInstance, targetID]);

  return (
    <div className="view_order">
      <div className="navtab_action">
        <div className="nav_tab">
          <div>
            <button
              type="button"
              className={activeTab === 1 ? "active" : ""}
              onClick={() => setActiveTab(1)}
            >
              <span>Personal Information</span>
            </button>
          </div>
          <div>
            <button
              type="button"
              className={activeTab === 2 ? "active" : ""}
              onClick={() => setActiveTab(2)}
            >
              <span>Payment Information</span>
            </button>
          </div>
        </div>
      </div>

      <div className="crud_view_content">
        <div className={`nav_content ${activeTab === 1 ? "active" : ""}`}>
          <h1>Name</h1>
          <p>{seller?.name}</p>

          <h1>Email</h1>
          <p>{seller?.email}</p>

          <h1>Phone</h1>
          <p>{seller?.mobile ? seller?.mobile : "Didn't Add Number"}</p>

          {seller?.dp && (
            <>
              <h1>Image</h1>
              <p>{seller?.dp}</p>
            </>
          )}
        </div>
      </div>

      <div className={`nav_content ${activeTab === 2 ? "active" : ""}`}>
        <SellerCardInfo cardInfo={cardInfo} />
      </div>
    </div>
  );
}

export default ViewSeller;
