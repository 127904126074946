import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function TotalStore({stores}) {

  // seller/totalStores

  // const [totalStores,setTotalStores] = useState("")
  // const axiosInstance = useAxiosInstance()
  // useEffect(() => {
  //       async function fetchAndSetTotalStores() {  
  //         const { data } = await axiosInstance.get("seller/totalStores");
  //         setTotalStores(data)
  //       }
  //       fetchAndSetTotalStores();
  //     }, [axiosInstance]);

    return (
      <div className="card">
          <p>Total Store:</p>
          {stores && <h3>{stores}</h3>}
      </div>
  
    )
  }
  
  export default TotalStore