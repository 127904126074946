import { useState, useContext, useEffect } from "react";
import { RiImage2Line, RiSearchLine } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";

import CreateCity from "./CreateCity";
import UpdateCity from "./UpdateCity";
import DeleteItem from "../../../Partials/Layouts/DeleteItem/DeleteItem";
import AppContext from "../../../../AppContext/AppContext";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import EditButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";
import CRUDButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";
import Modal from "../../../Partials/Elements/Modal/Modal";

import "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDBoilerPlate.css";

import "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDBoilerPlate.css";
import "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CRUDHeader.css";
import "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ActionButtonsCell/ActionButtonsCell.css";
import "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDViewBoilerPlate/CRUDViewBoilerPlate.css";
import {  CITY_API } from "../../../../Utilities/APIs/APIs";

function City() {
  const { stateId } = useParams();
  const [search, setSearch] = useState("");
  const [citys, setCitys] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const { employee } = useContext(AppContext);

  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();

  
  useEffect(() => {
    async function fetchAndSetCitys() {
      const { data } = await axiosInstance.get(CITY_API + "cityByState/" + stateId);
      setCitys(data);
    }
    fetchAndSetCitys();
  }, [axiosInstance, toggleFetch,stateId]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <h1 className="heading">City ({citys?.length} in total)</h1>
          {/* <div>
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="input_field"
              placeholder="Search"
              style={{ width: "400px" }}
            />
            <RiSearchLine
              style={{
                width: "30px",
                height: "30px",
                marginLeft: "-50px",
                marginBottom: "20px",
                color: "var(--white-2)",
              }}
            />
          </div> */}

          <CreateButton
            screenTopicSingular={"City"}
            setShowCreateForm={setShowCreateForm}
            setShowModal={setShowModal}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="City" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {citys?.map((city) => (
              <CRUDTableRow key={city._id}>
                <ShortTextCell text={city.name} />

                <td className="action_button_cell">
                  <EditButton
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={city._id}
                    setTargetID={setTargetID}
                  />
                  <DeleteButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={city._id}
                    setTargetID={setTargetID}
                  />
                  <CRUDButton
                  dataTip='Street'
                  dataFor='Street Item'
                    handleClick={() =>
                      navigate("/address/state/city/street/" + city._id)
                    }
                  >
                    <RiImage2Line />
                  </CRUDButton>
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>

      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create City`) ||
            (showUpdateForm && `Update City `) ||
            (showDeleteSection && `Delete City`)
          }
        >
          {showCreateForm && (
            <CreateCity
              employee={employee}
              stateId={stateId}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateCity
            employee={employee}
            stateId={stateId}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
            <DeleteItem
              api={CITY_API}
              targetID={targetID}
              employee={employee}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}
    </>
  );
}

export default City;
