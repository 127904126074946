import React from "react";
import { Link } from "react-router-dom";
import useAxiosAuthInstance from "../../../../../../Utilities/Hooks/AxiosInstanceHooks/useAxiosAuthInstance";
import { FORGOT_PASSWORD_API } from "../../../../../../Utilities/APIs/AuthAPIs/AuthAPIs";

export default function SentEmailScreen({ email, setEmailSent }) {
  const axiosAuthInstance = useAxiosAuthInstance();

  async function handleResend() {
    if (email.length > 0) {
      const forgotPass = await axiosAuthInstance.post(FORGOT_PASSWORD_API, {
        email,
      });
      if (forgotPass) {
        setEmailSent(true);
      }
    }
  }

  return (
    <div className="form_wrapper">
      <h3>Check your email</h3>
      <p className="auth_status">
        If we found an account with <span>{email ? email : ""}</span>, an email
        has been sent. Please check your email in a moment.
      </p>
      <div className="dha_box" style={{ marginTop: "0" }}>
        <p className="body_text">Didn’t receive a link?</p>
        <button type="submit" className="resend_btn" onClick={handleResend}>
          resend email
        </button>
      </div>
      <Link to="/login" className="btn_text  forgot_pass">
        Back to Login
      </Link>
    </div>
  );
}
