import React from "react";
import { IoCaretDownOutline, IoCaretUpOutline } from "react-icons/io5";

export default function DropdownButton({ isOpen, setIsOpen }) {
  const handleToggle = () => {
    setIsOpen((prevState) => !prevState);
  };
  return (
    <button className="user_profile_btn" onClick={handleToggle}>
      {isOpen ? <IoCaretUpOutline /> : <IoCaretDownOutline />}
    </button>
  );
}
