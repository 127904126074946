import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { Product_API } from "../../../../Utilities/APIs/APIs";
import SelectInput from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import Modal from "../../../Partials/Elements/Modal/Modal";
import CreateProduct from "./components/CreateProduct/CreateProduct";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import ViewProduct from "./components/ViewProduct";
import DeleteItem from "../../../Partials/Layouts/DeleteItem/DeleteItem";
import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";
import EditButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import UpdateProducts from "./components/UpdateProducts/UpdateProducts";
import Pagination from "./components/Pagination/Pagination";

function NewProduct() {
  const { storeId } = useParams();
  // const [search, setSearch] = useState('')
  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const [filter, setFilter] = useState("all");
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetProducts() {
      const { data } = await axiosInstance.get(
        `${Product_API}?page=${page}&&filter=${filter}`
      );
      console.log(data);
      setProducts(data.products);
      setPages(data.totalProducts);
    }
    fetchAndSetProducts();
  }, [axiosInstance, toggleFetch, storeId, filter, page]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <div className="screen_header">
          <h1 className="screen_heading">
            Products ({products?.length} in total)
          </h1>
          <SelectInput value={filter} setState={setFilter}>
            <option value={"all"}>All</option>
            <option value={true}>Archive</option>
            <option value={false}>Active</option>
          </SelectInput>

          <CreateButton
            screenTopicSingular={"Product"}
            setShowCreateForm={setShowCreateForm}
            setShowModal={setShowModal}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Image" />
            <CRUDth th="Name" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {products.length &&
              products?.map((product) => (
                <CRUDTableRow key={product._id}>
                  <figure style={{ width: "100px", height: "100px" }}>
                    <img
                      src={process.env.REACT_APP_SPACES_URL + product.cardImage}
                      alt="product"
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </figure>
                  <ShortTextCell text={product.name} />
                  <ShortTextCell text={product.precedence} />
                  <td className="action_button_cell">
                    <ViewButton
                      setShowModal={setShowModal}
                      setShowViewSection={setShowViewSection}
                      targetID={product._id}
                      setTargetID={setTargetID}
                    />

                    <EditButton
                      setShowModal={setShowModal}
                      setShowUpdateForm={setShowUpdateForm}
                      targetID={product._id}
                      setTargetID={setTargetID}
                    />

                    <DeleteButton
                      setShowModal={setShowModal}
                      setShowDeleteSection={setShowDeleteSection}
                      targetID={product._id}
                      setTargetID={setTargetID}
                    />
                  </td>
                </CRUDTableRow>
              ))}
          </tbody>
        </CRUDTable>
        <Pagination page={page} setPage={setPage} pages={pages} />
      </div>

      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Product`) ||
            (showUpdateForm && `Update Product `) ||
            (showDeleteSection && `Archive Product`)
          }
        >
          {showCreateForm && (
            <CreateProduct
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateProducts
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
            <>
              <DeleteItem
                api={Product_API}
                targetID={targetID}
                setShowModal={setShowModal}
                setShowDeleteSection={setShowDeleteSection}
                triggerFetch={triggerFetch}
              />
            </>
          )}
        </Modal>
      )}

      {showModal && showViewSection && (
        <Modal
          handleClose={handleClose}
          modalHeading={showViewSection && `View Product`}
        >
          <ViewProduct targetID={targetID} />
        </Modal>
      )}
    </>
  );
}

export default NewProduct;
