import { useState, useContext, useEffect } from "react";
import { RiImage2Line, RiSearchLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import { FaRegBuilding } from "react-icons/fa";

import AppContext from "../../../AppContext/AppContext";
import CreateButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import EditButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import DeleteButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";
import CRUDButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";
import Modal from "../../Partials/Elements/Modal/Modal";


import "../../../Components/Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDBoilerPlate.css"
import '../../../Components/Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CRUDHeader.css'
import '../../../Components/Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ActionButtonsCell/ActionButtonsCell.css'
import '../../../Components/Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDViewBoilerPlate/CRUDViewBoilerPlate.css'
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";
import CreateAddress from "./CreateAddress";
import UdpateAddress from "./UdpateAddress";
import DeleteItem from "../../Partials/Layouts/DeleteItem/DeleteItem";
import { STATE_API } from "../../../Utilities/APIs/APIs";



function Address() {
 
  const [search, setSearch] = useState("");
  const [addresses, setAddresses] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const { employee } = useContext(AppContext);

  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    async function fetchAndSetAddresses() {
      const { data } = await axiosInstance.get(STATE_API);
      setAddresses(data);
    }
    fetchAndSetAddresses();
  }, [axiosInstance, toggleFetch]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <h1 className="heading">State ({addresses?.length} in total)</h1>
          {/* <div>
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="input_field"
              placeholder="Search"
              style={{ width: "400px" }}
            />
            <RiSearchLine style={{width: "30px",height: "30px",marginLeft: "-50px",marginBottom:"20px",color: "var(--white-2)",}}
            />
          </div> */}

          <CreateButton
            screenTopicSingular={"State"}
            setShowCreateForm={setShowCreateForm}
            setShowModal={setShowModal}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="State" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {addresses?.map((address) => (
              <CRUDTableRow key={address._id}>
                <ShortTextCell text={address.name} />
               

                <td className="action_button_cell">
              
                  <EditButton
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={address._id}
                    setTargetID={setTargetID}
                  />
                  <DeleteButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={address._id}
                    setTargetID={setTargetID}
                  />
                  <CRUDButton
                  dataTip='City'
                  dataFor='City Item'
                    handleClick={() => navigate("/address/state/city/" + address._id)}
                  >
                    {
        location.pathname === "/address" ?
        <FaRegBuilding />
        :
        <RiImage2Line />
      }
                  </CRUDButton>
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>

      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create State`) ||
            (showUpdateForm && `Update State `) ||
            (showDeleteSection && `Delete State`)
          }
        >
          {showCreateForm && (
            <CreateAddress
            employee={employee}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UdpateAddress
            employee={employee}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
            <DeleteItem
              api={STATE_API}
              targetID={targetID}
              employee={employee}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}

     

    </>
  );
}

export default Address;
