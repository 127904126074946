import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import { SIZE_API } from "../../../../../Utilities/APIs/APIs";


function ViewSize  ({targetID})  {
    const [sizeInfo,setSizeInfo] = useState(null)
    const axiosInstance = useAxiosInstance();

    useEffect(() => {
      async function fetchAndsetSizeInfo() {
        const { data } = await axiosInstance.get(SIZE_API+ "getSingleSize/" + targetID);
        setSizeInfo(data);
      }
      fetchAndsetSizeInfo();
    }, [targetID, axiosInstance,]);
    return (
        <div className="crud_view_content">
     
        <>
          <h1>Size Name</h1>
          <p>{sizeInfo?.name}</p>

          <h1>Precedence</h1>
          <p>{sizeInfo?.precedence}</p>
     
        </>

        </div>
    );
};

export default ViewSize;