import React from "react";
import Modal from "../../../../../Partials/Elements/Modal/Modal";
import ViewCustomer from "./components/ViewCustomer";

export default function CustomerModal({
  showModal,
  handleClose,
  targetID,
  showViewSection,
}) {
  return (
    <>
      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={showViewSection && `View Customer`}
        >
          {showViewSection && <ViewCustomer targetID={targetID} />}
        </Modal>
      )}
    </>
  );
}
