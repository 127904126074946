import { useState, useContext, useEffect } from "react";
import {
  RiArrowLeftRightFill,
  RiImage2Line,
  RiSearchLine,
  RiShoppingCartLine,
  RiStore3Fill,
} from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import { FcAcceptDatabase } from "react-icons/fc";
import { FaWindowClose } from "react-icons/fa";

import CRUDButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";
import Modal from "../../Partials/Elements/Modal/Modal";
import ViewStore from "./ViewStore/ViewStore";
import AppContext from "../../../AppContext/AppContext";
import CRUDTable from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ImageCell from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell";
import ViewButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";

import "../../../Components/Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDBoilerPlate.css";
import "../../../Components/Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CRUDHeader.css";
import "../../../Components/Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ActionButtonsCell/ActionButtonsCell.css";
import "../../../Components/Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDViewBoilerPlate/CRUDViewBoilerPlate.css";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";

// import CreateButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
// import EditButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
// import DeleteButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";
// import CreateStore from "./CreateStore/CreateStore";
// import UpdateStore from "./UpdateStore/UpdateStore";
// import DeleteItem from "../../Partials/Layouts/DeleteItem/DeleteItem";

import { SELLER_ALL_STORE } from "../../../Utilities/APIs/APIs";
import PermittedToSellApparel from "./PermittedToSellApparel";

function SellerStore() {
  const { sellerId } = useParams();
  const [search, setSearch] = useState("");
  const [stores, setStores] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const [permittedToSellApparel, setPermittedToSellApparel] = useState(false);
  const { employee } = useContext(AppContext);

  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchAndSetStores() {
      const { data } = await axiosInstance.get(SELLER_ALL_STORE + sellerId);
      setStores(data);
    }
    fetchAndSetStores();
  }, [axiosInstance, toggleFetch, sellerId]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
    setPermittedToSellApparel(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <h1 className="heading">Store ({stores?.length} in total)</h1>
          <div>
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="input_field"
              placeholder="Search"
              style={{ width: "400px" }}
            />
            <RiSearchLine
              style={{
                width: "30px",
                height: "30px",
                marginLeft: "-50px",
                marginBottom: "20px",
                color: "var(--white-2)",
              }}
            />
          </div>

          {/* <CreateButton
            screenTopicSingular={"Store"}
            setShowCreateForm={setShowCreateForm}
            setShowModal={setShowModal}
          /> */}
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Name" />
            <CRUDth th="Image" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {stores?.map((store) => (
              <CRUDTableRow key={store._id}>
                <ShortTextCell text={store.name} />
                <ImageCell imgSrc={store.image} />

                <td className="action_button_cell">
                  <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={store._id}
                    setTargetID={setTargetID}
                  />
                  {/* <EditButton
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={store._id}
                    setTargetID={setTargetID}
                  />
                  <DeleteButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={store._id}
                    setTargetID={setTargetID}
                  /> */}
                  <CRUDButton
                    dataTip="Products"
                    dataFor="Products Item"
                    handleClick={() =>
                      navigate("/seller/sellerStore/products/" + store._id)
                    }
                  >
                    <RiImage2Line />
                  </CRUDButton>

                  <CRUDButton
                    dataTip="Orders"
                    dataFor="Order Item"
                    handleClick={() =>
                      navigate("/seller/sellerStore/orderScreen/" + store._id)
                    }
                  >
                    <RiShoppingCartLine />
                  </CRUDButton>

                  {!store?.permittedToSellApparel && (
                    <CRUDButton
                      dataTip="Give Permission To Sell Apparel"
                      dataFor="Give Permission To Sell Apparel"
                      handleClick={() => {
                        setPermittedToSellApparel(true);
                        setShowModal(true);
                        setTargetID(store._id);
                      }}
                    >
                      <FaWindowClose />
                    </CRUDButton>
                  )}

                  {store?.permittedToSellApparel && (
                    <CRUDButton
                      dataTip="Remove Permission To Sell Apparel"
                      dataFor="Remove Permission To Sell Apparel"
                      handleClick={() => {
                        setPermittedToSellApparel(true);
                        setShowModal(true);
                        setTargetID(store._id);
                      }}
                    >
                      <FcAcceptDatabase />
                    </CRUDButton>
                  )}
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>

      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Store`) ||
            (showUpdateForm && `Update Store `) ||
            (showDeleteSection && `Delete Store`)
          }
        >
          {/* {showCreateForm && (
            <CreateStore
              employee={employee}
              setShowCreateFor
              
              m={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateStore
              employee={employee}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
            <DeleteItem
              api={"deleteStore/"}
              targetID={targetID}
              employee={employee}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
            />
          )} */}
        </Modal>
      )}

      {showModal && showViewSection && (
        <Modal
          handleClose={handleClose}
          modalHeading={showViewSection && `View Store Slider`}
          triggerFetch={triggerFetch}
        >
          <ViewStore targetID={targetID} employee={employee} />
        </Modal>
      )}
      {showModal && permittedToSellApparel && (
        <Modal
          handleClose={handleClose}
          modalHeading={permittedToSellApparel && `Permitted to sell apparel`}
          triggerFetch={triggerFetch}
        >
          <PermittedToSellApparel
            targetID={targetID}
            setPermittedToSellApparel={setPermittedToSellApparel}
            setShowModal={setShowModal}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}
    </>
  );
}

export default SellerStore;
