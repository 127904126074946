import "./SellerCardInfo.css";

function SellerCardInfo({ cardInfo }) {
  return (
    <>
      {cardInfo && cardInfo.length > 0 ? (
        cardInfo.map((card) => (
          <div key={card._id} className="payment_card_container">
            <h3>Bank Name : </h3>
            <p>{card.bankName}</p>

            <h3>Bank Address : </h3>
            <p>{card.bankAddress}</p>

            <h3>Branch Name</h3>
            <p>{card.branchName ? card.branchName : "N/A"}</p>
            <h3>Account Holder Name : </h3>
            <p>{card.accountHolderName}</p>

            <h3>Account Number : </h3>
            <p>{card.accountNumber}</p>

            <h3>Routing Number: </h3>
            <p>{card.routingNumber}</p>

            <h3>Mobile : </h3>
            <p>{card.mobile}</p>

            <h3>Crypto Wallet Address</h3>
            {card.walletAddress ? (
              <p>{card.walletAddress}</p>
            ) : (
              <p>Didn't Add Crypto Wallet Address</p>
            )}
          </div>
        ))
      ) : (
        <p>Don't Added Card Info</p>
      )}
    </>
  );
}

export default SellerCardInfo;
