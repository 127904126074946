import React from "react";
import SelectInput from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";

export default function EmployeesHeader({ employees, filter, setFilter }) {
  return (
    <div className="body_header">
      <h1 className="heading">{`Employees (${employees?.length} in total)`}</h1>
      <SelectInput value={filter} setState={setFilter}>
        <option value={"all"}>All</option>
        <option value={"archive"}>Archive</option>
        <option value={"active"}>Active</option>
      </SelectInput>
    </div>
  );
}
