
import { ThreeDots } from "react-loader-spinner";

import "./Loader.css";

function Loader() {

  return (
    <div className="loader">
      <ThreeDots
        visible={true}
        height="80"
        width="80"
        color="#42378df8"
        radius="9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  ) 
}

export default Loader;