import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { ORDERS_API } from "../../../../../../Utilities/APIs/APIs";



function ViewItems({ targetID }) {
  const [orderItemInfo, setorderItemInfo] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndsetorderItemInfo() {
      const { data } = await axiosInstance.get(ORDERS_API + "/singleItem/" + targetID);
      setorderItemInfo(data);
    }
    fetchAndsetorderItemInfo();
  }, [targetID, axiosInstance]);

  return (
    <div className="crud_view_content">
      {orderItemInfo && (
        <>
          <h1>Product Info</h1>
          <p>Name: {orderItemInfo.item.name}</p>
          <p>Description: {orderItemInfo.item.description}</p>
          {/* <p>Status: {orderItemInfo.item.productStatus}</p> */}

          <p>Quantity: {orderItemInfo.quantity}</p>
          <p>Retail Cost: {orderItemInfo.item.retailCost}</p>
          <p>Total: {orderItemInfo.total}</p>
         
         
        </>
      )}
    </div>
  );
}

export default ViewItems;
