import { Link } from "react-router-dom";
import Logo from "../../../../Elements/Logo/Logo";

export default function NavbarLogo() {
  return (
    <div id="brand">
      <Link to={"/"}>
        <Logo />
      </Link>
    </div>
  );
}
