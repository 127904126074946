import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RiImage2Line } from "react-icons/ri";


import AppContext from "../../../../../AppContext/AppContext";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import ViewOrderDetails from "../../../SellerStore/Order/ViewOrderDetails/ViewOrderDetails";
import Modal from "../../../../Partials/Elements/Modal/Modal";
import CRUDTable from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ViewButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import CRUDButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";

function CustomerOrders() {
  const { customerId, orderStatus } = useParams();
  const { toggleFetch } = useContext(AppContext);
  const [orders, setOrders] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);

  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();
  useEffect(() => {
    async function fetchAndsetOrders() {
      const { data } = await axiosInstance.get(
        `customer/getCustomerOrders/${customerId}/${orderStatus}`
      );
      setOrders(data);
    }
    fetchAndsetOrders();
  }, [axiosInstance, customerId, orderStatus, toggleFetch]);

  function handleClose() {
    setShowModal(false);
    setShowViewSection(false);
  }

  return (
    <>
      <div className="crud_holder">
        <div className="screen_header">
          <h1 className="screen_heading">Orders ({orders?.length} in total)</h1>
          {/* <div>
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="input_field"
              placeholder="Search"
              style={{ width: "400px" }}
            />
            <RiSearchLine
              style={{
                width: "30px",
                height: "30px",
                marginLeft: "-50px",
                color: "var(--white-2)",
              }}
            />
          </div> */}
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Customer Name" />
            <CRUDth th="Date" />
            <CRUDth th="Total" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {orders?.map((order) => (
              <CRUDTableRow key={order._id}>
                <ShortTextCell text={order.customer?.name} />
                <ShortTextCell
                  text={new Date(order.createdAt).toLocaleDateString()}
                />
                <ShortTextCell text={order.total} />

                <td className="action_button_cell">
                  <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={order._id}
                    setTargetID={setTargetID}
                  />

                  <CRUDButton
                    dataTip="Order Items"
                    dataFor="Items"
                    handleClick={() =>
                      navigate("/customer/orderItems/" + order._id)
                    }
                  >
                    <RiImage2Line />
                  </CRUDButton>
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>

      {showModal && showViewSection && (
        <Modal
          handleClose={handleClose}
          modalHeading={showViewSection && `View Order Details`}
        >
          <ViewOrderDetails targetID={targetID} />
        </Modal>
      )}
    </>
  );
}

export default CustomerOrders;
