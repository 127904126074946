import { RiGiftLine } from "react-icons/ri";
import NavCard from "../../../Partials/Layouts/NavCardList/NavCard/NavCard";
import NavCardList from "../../../Partials/Layouts/NavCardList/NavCardList";


function ProductFeatures() {
  

  return (
    <NavCardList numOfCards="five">

      <NavCard
        cardName={`Size`}
        navCardLink={`/product/size`}
      >
        <RiGiftLine />
      </NavCard>

      <NavCard
        cardName={`Color`}
        navCardLink={`/product/color`}
      >
        <RiGiftLine />
      </NavCard>

    </NavCardList>
  );
}

export default ProductFeatures;
