import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import {  RiSearchLine } from "react-icons/ri";


import "./Product.css";
import AppContext from "../../../../AppContext/AppContext";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ImageCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import Modal from "../../../Partials/Elements/Modal/Modal";
import ViewProduct from "./ViewProduct";
import { SINGLE_STORE_PRODUCT } from "../../../../Utilities/APIs/APIs";


function Products() {
  const { storeId } = useParams();
  const [search, setSearch] = useState("");
  const [products, setProducts] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);

  const { employee } = useContext(AppContext);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetProducts() {
      const { data } = await axiosInstance.get( SINGLE_STORE_PRODUCT + storeId);
      setProducts(data);
    }
    fetchAndSetProducts();
  }, [axiosInstance, storeId]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

 

  return (
    <>
      <div className="crud_holder">
        <div className="screen_header">
          <h1 className="screen_heading">
            Products ({products?.length} in total)
          </h1>
          <div>
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="input_field"
              placeholder="Search"
              style={{ width: "400px" }}
            />
            <RiSearchLine
              style={{
                width: "30px",
                height: "30px",
                marginLeft: "-50px",
                color: "var(--white-2)",
              }}
            />
          </div>

        
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Name" />
            <CRUDth th="Image" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {products?.map((product) => (
              <CRUDTableRow key={product._id}>
                <ShortTextCell text={product.name} />
                {/* <ImageCell imgSrc={product.images && product.images[0]} /> */}
                <ImageCell imgSrc={product.cardImage} />

                <td className="action_button_cell">
                  <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={product._id}
                    setTargetID={setTargetID}
                  />
                 
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>

      {showModal && (
        <Modal

          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Product`) ||
            (showUpdateForm && `Update Product `) ||
            (showDeleteSection && `Delete Product`)
          }
        >
         
        </Modal>
      )}

      {showModal && showViewSection && (
        <Modal
  
          handleClose={handleClose}
          modalHeading={showViewSection && `View Product Info`}
        >
          <ViewProduct 
          targetID={targetID} 
          employee={employee}

           />
        </Modal>
      )}
    </>
  );
}

export default Products;
