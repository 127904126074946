import { Link } from "react-router-dom";
import RecoverPassForm from "./components/RecoverPassForm";

export default function RecoverPassScreen({ email, setEmail, setEmailSent }) {
  return (
    <div className="form_wrapper">
      <h3>Recover Password</h3>
      <RecoverPassForm
        email={email}
        setEmail={setEmail}
        setEmailSent={setEmailSent}
      />
      <Link to="/login" className="btn_text forgot_pass">
        Back to Login
      </Link>
    </div>
  );
}
