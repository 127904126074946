import { useState } from "react";
import useAxiosInstance from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import { EMPLOYEE_INVITES_API } from "../../../../../../../Utilities/APIs/AuthAPIs/AuthAPIs";
import Form from "../../../../../../Partials/Layouts/Forms/Form";
import EmailInput from "../../../../../../Partials/Layouts/Forms/FormInputs/EmailInput/EmailInput";
import FormSubmitButton from "../../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
// import { EMPLOYEES_API } from "../../../../Utilities/APIs"


function CreateInvite({
  employee,
  setShowCreateForm,
  setShowModal,
  triggerFetch,
}) {
  const [email, setEmail] = useState("");
  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();
    const itemData = {};
    itemData.email = email;
    const response = await axiosInstance.post(EMPLOYEE_INVITES_API, itemData);
    if (response) {
      setEmail("");
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <EmailInput
        label={"Email"}
        value={email}
        placeholder={"Please Enter an Email"}
        setState={setEmail}
      />
      <FormSubmitButton text="Send Invite to Email" />
    </Form>
  );
}

export default CreateInvite;
