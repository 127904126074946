import React, { useContext } from "react";
import { BsPerson } from "react-icons/bs";
import { IoPowerSharp } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import AppContext from "../../../../../AppContext/AppContext";

export default function DropDrownItems({ isOpen }) {
  const navigate = useNavigate();
  const { logout } = useContext(AppContext);

  function handleLogout() {
    logout();
    navigate("/login");
  }

  return (
    <div className={`user_dropdown ${isOpen ? "show" : ""}`}>
      <div className="dropdown_inner">
        <Link to="/profile">
          profile
          <BsPerson />
        </Link>
        <button onClick={handleLogout}>
          logout
          <IoPowerSharp />
        </button>
      </div>
    </div>
  );
}
