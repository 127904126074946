import { useState } from "react";

import { PAYMENT_TO_SELLER } from "../../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../Partials/Layouts/Forms/Form";
import NumberInput from "../../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";
import DateInput from "../../../../Partials/Layouts/Forms/FormInputs/DateInput/DateInput";
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";

function CreateSellerPayment({  setShowModal, triggerFetch,sellerId }) {

  const axiosInstance = useAxiosInstance();
  const [amount, setAmount] = useState(0);
  const [dateOfPayment, setDateOfPayment] = useState("");


  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = { seller:sellerId,amount,dateOfPayment };

    const response = await axiosInstance.post(PAYMENT_TO_SELLER, itemData);

    if (response.data) {
      setShowModal(false);
      triggerFetch();
    }
  }
  return (
    <Form onSubmit={handleSubmit} hasImage>

      <NumberInput
      label={"Amount"}
      setState={setAmount}
      placeholder={"Enter Amount"}
      value={amount}
      />

      <DateInput
        label={`Date Of Payment`}
        value={dateOfPayment}
        placeholder={`Enter Date of Payment`}
        setState={setDateOfPayment}
      />

      <FormSubmitButton text="Create Payment" />
    </Form>
  );
}

export default CreateSellerPayment;
