import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import { CUSTOMER_API } from "../../../../../../../Utilities/APIs/APIs";

function ViewCustomer({ targetID }) {
  const [customer, setCustomer] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetCustomer() {
      const { data } = await axiosInstance.get(CUSTOMER_API + targetID);
      setCustomer(data);
    }
    fetchAndSetCustomer();
  }, [axiosInstance, targetID]);

  return (
    <div className="crud_view_content">
      <h1>Name</h1>
      <p>{customer?.name}</p>

      <h1>Email</h1>
      <p>{customer?.email}</p>

      <h1>Phone</h1>
      <p>{customer?.mobile ? customer?.mobile : "Didn't Add Number"}</p>

      {customer?.dp && (
        <>
          <h1>Image</h1>
          <p>{customer?.dp}</p>
        </>
      )}
    </div>
  );
}

export default ViewCustomer;
