import InputBoilerplate from "../InputBoilerplate";

function DateInput ({label, value, placeholder, setState}) {
    return (
        <InputBoilerplate
        label={label}
        type={'date'}
        value={value}
        placeholder={placeholder}
        setState={setState}
       
    />
    );
};

export default DateInput;