import { useState } from "react";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import NumberInput from "../../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import { SIZE_API } from "../../../../../Utilities/APIs/APIs";
import SelectInput from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";

function CreateSize({ setShowCreateForm, setShowModal, triggerFetch, type }) {
  const axiosInstance = useAxiosInstance();

  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [precedence, setPrecedence] = useState(1);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = {
      name,
      gender,
      type,
      precedence,
    };

    const response = await axiosInstance.post(
      SIZE_API + "/createSize",
      itemData
    );

    console.log(response);

    if (response.data) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }
  console.log(gender, type);
  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Size Name`}
        value={name}
        placeholder={`Enter Size Name`}
        setState={setName}
      />

      <SelectInput setState={setGender}>
        <option disabled hidden selected>
          Gender
        </option>
        <option value="men">Men</option>
        <option value="women">Women</option>
      </SelectInput>

      {/* <NumberInput
        label={`Precedence`}
        value={precedence}
        placeholder={`Enter Precedence`}
        setState={setPrecedence}
      /> */}

      <FormSubmitButton text="Create Size" />
    </Form>
  );
}

export default CreateSize;
