import { useParams } from "react-router-dom";
import { RiGiftLine } from "react-icons/ri";

import NavCardList from "../../../../Partials/Layouts/NavCardList/NavCardList";
import NavCard from "../../../../Partials/Layouts/NavCardList/NavCard/NavCard";



function CustomerOrderNavlink() {
    const { customerId } = useParams();

    // const axiosInstance = useAxiosInstance()
    // const [orders, setOrders] = useState(null)

    // useEffect(() => {
    //     async function fetchAndSetItems() {
    //         const {data} = await axiosInstance.get(ORDERS_API +'/getAllOrders/' +  customerId)
    //         setOrders(data)
    //     }
    //     fetchAndSetItems()

    // }, [axiosInstance,customerId])

    // useEffect(() => {
    //     async function fetchAndSetMyOrders() {
    //       try {
    //         const { data } = await axiosInstance.get(
    //           "getMyOrdersByStatus/" + status
    //         );
    //         setMyOrders(data);
    //       } catch (error) {
    //         console.error("Error fetching orders:", error);
    //       }
    //     }
    //     fetchAndSetMyOrders();
    //   }, [axiosInstance, status]);

  return (
    <NavCardList numOfCards="five">

      <NavCard
        // cardName={`Processing(${orders?.filter(i => i.orderStatus === 'processing')?.length})`}
        cardName={`Processing`}
        navCardLink={`/customer/order/${customerId}/processing`}
      >
        <RiGiftLine />
      </NavCard>

      <NavCard
        // cardName={`To Be Delivered(${orders?.filter(i => i.orderStatus === 'toBeDelivered')?.length})`}
        cardName={`To Be Delivered`}
        navCardLink={`/customer/order/${customerId}/toBeDelivered`}
      >
        <RiGiftLine />
      </NavCard>

      <NavCard
        // cardName={`Shipped(${orders?.filter(i => i.orderStatus === 'shipped')?.length})`}
        cardName={`Shipped`}
        navCardLink={`/customer/order/${customerId}/shipped`}
      >
        <RiGiftLine />
      </NavCard>

      <NavCard
        // cardName={`Completed(${orders?.filter(i => i.orderStatus === 'completed')?.length})`}
        cardName={`Completed`}
        navCardLink={`/customer/order/${customerId}/completed`}
      >
        <RiGiftLine />
      </NavCard>

      <NavCard
        // cardName={`Canceled(${orders?.filter(i => i.orderStatus === 'canceled')?.length})`}
        cardName={`Canceled`}
        navCardLink={`/customer/order/${customerId}/canceled`}
      >
        <RiGiftLine />
      </NavCard>


    </NavCardList>
  );
}


export default CustomerOrderNavlink