import React from "react";
import CRUDTable from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import CRUDButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";
import { RiDeleteBin2Line, RiMailSendLine } from "react-icons/ri";

export default function InviteEmployeeTable({
  invitedEmployees,
  employees,
  setShowModal,
  setShowDeleteSection,
  setShowResendSection,
  setTargetID,
}) {
  return (
    <CRUDTable>
      <CRUDTableHeader>
        <CRUDth th="Email Address" />
        <CRUDth th="Invited By" />
        <CRUDth th="Actions" />
      </CRUDTableHeader>
      <tbody>
        {invitedEmployees?.map((invite) => (
          <CRUDTableRow key={invite._id}>
            <ShortTextCell text={invite.email} />
            <ShortTextCell
              text={employees?.find((e) => e._id === invite.employee)?.name}
            />

            <td className="action_button_cell">
              <CRUDButton
                dataTip="Delete"
                dataFor="Delete Invite"
                handleClick={() => {
                  setShowModal(true);
                  setShowDeleteSection(true);
                  setTargetID(invite._id);
                }}
              >
                <RiDeleteBin2Line />
              </CRUDButton>
              <CRUDButton
                dataTip="Resend"
                dataFor="Resend Item"
                handleClick={() => {
                  setShowModal(true);
                  setShowResendSection(true);
                  setTargetID(invite._id);
                }}
              >
                <RiMailSendLine />
              </CRUDButton>
            </td>
          </CRUDTableRow>
        ))}
      </tbody>
    </CRUDTable>
  );
}
