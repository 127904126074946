import Modal from "../../../../../Partials/Elements/Modal/Modal";
import ViewSeller from "./components/ViewSeller";

export default function SellerModal({
  showModal,
  employee,
  handleClose,
  targetID,
  showViewSection,
}) {
  return (
    <>
      {showModal && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View Seller`}
        >
          {showViewSection && (
            <ViewSeller targetID={targetID} employee={employee} />
          )}
        </Modal>
      )}
    </>
  );
}
