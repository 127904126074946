import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { SELLER_SINGLE_STORE } from "../../../../Utilities/APIs/APIs";
import Image from "../../../Partials/Elements/Image/Image";

function ViewStore  ({targetID})  {
    const [storeInfo,setStoreInfo] = useState(null)
    const axiosInstance = useAxiosInstance();

    useEffect(() => {
      async function fetchAndsetStoreInfo() {
        const { data } = await axiosInstance.get(SELLER_SINGLE_STORE + targetID);
        setStoreInfo(data);
      }
      fetchAndsetStoreInfo();
    }, [targetID, axiosInstance,]);
    return (
        <div className="crud_view_content">
            { storeInfo && (
        <>
          <h1>Store Name</h1>
          <p>{storeInfo?.name}</p>

          <h1>Email</h1>
          <p>{storeInfo.email}</p>

          <h1>Mobile Number</h1>
          <p>{storeInfo.mobile}</p>

          <h1>Address</h1>
          <p>{storeInfo.address}</p>

          <h1>Slug</h1>
          <p>{storeInfo.slug}</p>

          <h1>Store Image</h1>
          <Image imgLink={storeInfo.image} imgAlt={"Card Front Image"} />
        </>
      )} 
        </div>
    );
};

export default ViewStore;