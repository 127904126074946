import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";

function PermittedToSellApparel({
  targetID,
  setPermittedToSellApparel,
  setShowModal,
  triggerFetch,
}) {
  const axiosInstance = useAxiosInstance();

  async function handlePermittedToSellApparel() {
    const response = await axiosInstance.patch(
      "seller/permittedToSellApparel/" + targetID
    );

    if (response) {
      setPermittedToSellApparel(false);
      setShowModal(false);
      triggerFetch();
    }
  }
  return (
    <div className="modal_delete_content">
      <p>Are you sure you want to make the Change</p>

      <div className="modal_group_buttons">
        <button
          className="delete"
          onClick={() => {
            handlePermittedToSellApparel();
          }}
        >
          Yes
        </button>

        <button
          className="cancel"
          onClick={() => {
            setShowModal(false);
            setPermittedToSellApparel(false);
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export default PermittedToSellApparel;
