import { useParams } from "react-router-dom";
import { RiGiftLine } from "react-icons/ri";
import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import { ORDERS_API } from "../../../../../Utilities/APIs/APIs";
import NavCardList from "../../../../Partials/Layouts/NavCardList/NavCardList";
import NavCard from "../../../../Partials/Layouts/NavCardList/NavCard/NavCard";




function OrderNavlink() {
    const { storeId } = useParams();
    const axiosInstance = useAxiosInstance()
    const [orders, setOrders] = useState(null)

    useEffect(() => {
        async function fetchAndSetItems() {
            const {data} = await axiosInstance.get(ORDERS_API +'/getAllOrders/' +  storeId)
            setOrders(data)
        }
        fetchAndSetItems()
    }, [axiosInstance,storeId])

  return (
    <NavCardList numOfCards="five">

      <NavCard
        cardName={`Processing(${orders?.filter(i => i.orderStatus === 'processing')?.length})`}
        navCardLink={`/sellers/store/order/${storeId}/processing`}
      >
        <RiGiftLine />
      </NavCard>

      <NavCard
        cardName={`To Be Delivered(${orders?.filter(i => i.orderStatus === 'toBeDelivered')?.length})`}
        navCardLink={`/sellers/store/order/${storeId}/toBeDelivered`}
      >
        <RiGiftLine />
      </NavCard>

      <NavCard
        cardName={`Shipped(${orders?.filter(i => i.orderStatus === 'shipped')?.length})`}
        navCardLink={`/sellers/store/order/${storeId}/shipped`}
      >
        <RiGiftLine />
      </NavCard>

      <NavCard
        cardName={`Completed(${orders?.filter(i => i.orderStatus === 'completed')?.length})`}
        navCardLink={`/sellers/store/order/${storeId}/completed`}
      >
        <RiGiftLine />
      </NavCard>

      <NavCard
        cardName={`Canceled(${orders?.filter(i => i.orderStatus === 'canceled')?.length})`}
        navCardLink={`/sellers/store/order/${storeId}/canceled`}
      >
        <RiGiftLine />
      </NavCard>


    </NavCardList>
  );
}


export default OrderNavlink