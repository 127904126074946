import {  useEffect, useState } from "react"
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";



function TotalOrderPayments({totalOrderValue}) {
   
        // console.log("order Value is",totalOrderValue)
  return (
    <div className="card">
        <p>Total Order Payment</p>
        <h3>$ {totalOrderValue?.totalAmount}</h3>
    </div>

  )
}

export default TotalOrderPayments