import CRUDTable from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ViewButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import CRUDButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";
import { RiMapPinLine, RiShoppingCartLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

export default function CustomerTable({
  customers,
  setShowModal,
  setTargetID,
  setShowViewSection,
}) {
  const navigate = useNavigate();

  return (
    <CRUDTable>
      <CRUDTableHeader>
        <CRUDth th="Customer Name" />
        <CRUDth th="Email" />
        <CRUDth th="Actions" />
      </CRUDTableHeader>
      <tbody>
        {customers?.map((customer) => (
          <CRUDTableRow key={customer._id}>
            <ShortTextCell text={customer.name} />

            <ShortTextCell text={customer.email} />
            <td className="action_button_cell">
              <ViewButton
                setShowModal={setShowModal}
                setShowViewSection={setShowViewSection}
                targetID={customer._id}
                setTargetID={setTargetID}
              />
              <CRUDButton
                dataTip="Address"
                dataFor="Address Item"
                handleClick={() => navigate("/address/" + customer._id)}
              >
                <RiMapPinLine />
              </CRUDButton>

              <CRUDButton
                dataTip="Order"
                dataFor="Customer Order Item"
                handleClick={() =>
                  navigate("/customer/orderScreen/" + customer._id)
                }
              >
                <RiShoppingCartLine />
              </CRUDButton>
            </td>
          </CRUDTableRow>
        ))}
      </tbody>
    </CRUDTable>
  );
}
