import BtnLoader from "../../../Elements/BtnLoader/BtnLoader";
import "./FormSubmitButton.css";

function FormSubmitButton({ text, loading }) {
  return (
    <div className="btn_wrapper">
      {!loading && (
        <button type="submit" className="submit w_100">
          {text}
        </button>
      )}{" "}
      {loading && (
        <button type="submit" className="submit w_100 loading_btn" disabled>
          <BtnLoader />
        </button>
      )}
    </div>
  );
}

export default FormSubmitButton;
