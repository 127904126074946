import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";

function ViewAddress({ targetID }) {
  const [address, setAddress] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetAddress() {
      const { data } = await axiosInstance.get(
        "customer/address/getSingleAddress/" + targetID
      );

      setAddress(data);
    }
    fetchAndSetAddress();
  }, [targetID, axiosInstance]);

  return (
    <div className="crud_view_content">

      <h1>Address Type</h1>
      <p>{address?.type}</p>

      <h1>State Name</h1>
      <p>{address?.state}</p>

      <h1>City Name</h1>
      <p>{address?.city}</p>

      <h1>Street</h1>
      <p>{address?.street}</p>

      <h1>Zip Code</h1>
      <p>{address?.zipCode}</p>
    </div>
  );
}

export default ViewAddress;
