import { useContext, useState } from "react";

import AppContext from "../../../../AppContext/AppContext";
import useFetchSeller from "./Hook/useFetchSeller";
import SellerHeader from "./components/SellerHeader";
import SellerTable from "./components/SellerTable";
import SellerModal from "./components/SellerModal/SellerModal";

function Sellers() {
  const sellers = useFetchSeller();
  const { employee } = useContext(AppContext);

  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);

  function handleClose() {
    setShowModal(false);
    setShowViewSection(false);
  }

  return (
    <>
      <div className="crud_holder">
        <SellerHeader sellers={sellers} />
        <SellerTable
          sellers={sellers}
          setShowModal={setShowModal}
          setTargetID={setTargetID}
          setShowViewSection={setShowViewSection}
        />
      </div>
      <SellerModal
        employee={employee}
        showModal={showModal}
        handleClose={handleClose}
        targetID={targetID}
        showViewSection={showViewSection}
      />
    </>
  );
}

export default Sellers;
