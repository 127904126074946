import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { Product_API } from "../../../../Utilities/APIs/APIs";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import EditButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";
import Pagination from "../NewProduct/components/Pagination/Pagination";
import Modal from "../../../Partials/Elements/Modal/Modal";
import CreateProduct from "../NewProduct/components/CreateProduct/CreateProduct";
import UpdateProducts from "../NewProduct/components/UpdateProducts/UpdateProducts";
import DeleteItem from "../../../Partials/Layouts/DeleteItem/DeleteItem";
import ViewProduct from "../NewProduct/components/ViewProduct";
import SharedProductHeader from "./components/SharedProductHeader";
import ArchiveItem from "../../../Partials/Layouts/ArchiveItem/ArchiveItem";

export default function SharedProductScreen() {
  const { storeId, type } = useParams();
  // const [search, setSearch] = useState('')
  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [totalProducts, setTotalProducts] = useState(0);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetProducts() {
      const { data } = await axiosInstance.get(
        `product/getProductByType?type=${type}&&page=${page}`,
        {}
      );
      setProducts(data.products);
      setTotalProducts(data.totalProducts);
      setPages(data.totalPage);
    }
    fetchAndSetProducts();
  }, [axiosInstance, toggleFetch, storeId, type, page]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <SharedProductHeader
          type={type}
          setShowModal={setShowModal}
          setShowCreateForm={setShowCreateForm}
        />
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Image" />
            <CRUDth th="Name" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {products.length &&
              products?.map((product) => (
                <CRUDTableRow key={product._id}>
                  <figure style={{ width: "100px", height: "100px" }}>
                    <img
                      src={process.env.REACT_APP_SPACES_URL + product.cardImage}
                      alt="product"
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </figure>
                  <ShortTextCell text={product.name} />
                  <td className="action_button_cell">
                    {/* <ViewButton
                      setShowModal={setShowModal}
                      setShowViewSection={setShowViewSection}
                      targetID={product._id}
                      setTargetID={setTargetID}
                    /> */}

                    <>
                      <EditButton
                        setShowModal={setShowModal}
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={product._id}
                        setTargetID={setTargetID}
                      />
                      <DeleteButton
                        product={product}
                        setShowModal={setShowModal}
                        setShowDeleteSection={setShowDeleteSection}
                        targetID={product._id}
                        setTargetID={setTargetID}
                      />
                    </>
                  </td>
                </CRUDTableRow>
              ))}
          </tbody>
        </CRUDTable>
        <Pagination
          currentPage={page}
          setPage={setPage}
          handlePageChange={(page) => setPage(page)}
          totalItems={totalProducts}
          totalPageNumber={pages}
        />
      </div>
      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Product`) ||
            (showUpdateForm && `Update Product `) ||
            (showDeleteSection && `Archive Product`)
          }
        >
          {showCreateForm && (
            <CreateProduct
              type={type}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateProducts
              type={type}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
            <>
              {/* <DeleteItem
                api={Product_API}
                targetID={targetID}
                setShowModal={setShowModal}
                setShowDeleteSection={setShowDeleteSection}
                triggerFetch={triggerFetch}
              /> */}
              <ArchiveItem
                api={Product_API}
                isArchive={
                  products.find((product) => product._id === targetID).isArchive
                }
                targetID={targetID}
                setShowModal={setShowModal}
                setShowDeleteSection={setShowDeleteSection}
                triggerFetch={triggerFetch}
              />
            </>
          )}
        </Modal>
      )}
      {showModal && showViewSection && (
        <Modal
          handleClose={handleClose}
          modalHeading={showViewSection && `View Product`}
        >
          <ViewProduct targetID={targetID} />
        </Modal>
      )}
    </>
  );
}
