import CRUDButton from "../CRUDButton";

import "./DeleteButton.css";
import { RiEyeCloseLine } from "react-icons/ri";
import { BsEye } from "react-icons/bs";

function DeleteButton({
  setShowModal,
  setShowDeleteSection,
  targetID,
  product,
  setTargetID,
}) {
  console.log("test");
  return (
    <>
      {product?.isArchive ? (
        <CRUDButton
          dataTip="Unarchive"
          dataFor="Unarchive Item"
          handleClick={() => {
            setShowModal(true);
            setShowDeleteSection(true);
            setTargetID(targetID);
          }}
          deleteButton
        >
          <RiEyeCloseLine />
        </CRUDButton>
      ) : (
        <CRUDButton
          dataTip="Archive"
          dataFor="Archive Item"
          handleClick={() => {
            setShowModal(true);
            setShowDeleteSection(true);
            setTargetID(targetID);
          }}
          deleteButton
        >
          <BsEye />
        </CRUDButton>
      )}
    </>
  );
}

export default DeleteButton;
