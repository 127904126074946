import React from "react";
import useAxiosAuthInstance from "../../../../../../../../Utilities/Hooks/AxiosInstanceHooks/useAxiosAuthInstance";
import { FORGOT_PASSWORD_API } from "../../../../../../../../Utilities/APIs/AuthAPIs/AuthAPIs";

export default function RecoverPassForm({ email, setEmail, setEmailSent }) {
  const axiosAuthInstance = useAxiosAuthInstance();

  async function handleSubmit(e) {
    e.preventDefault();
    if (email.length > 0) {
      const forgotPass = await axiosAuthInstance.post(FORGOT_PASSWORD_API, {
        email,
      });
      if (forgotPass) {
        setEmailSent(true);
      }
    }
  }
  return (
    <form action="/">
      <div className="input_group">
        <label className="input_field_label caption bold">Email</label>
        <input
          type="email"
          className="input_field body_text"
          placeholder="Enter Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <button type="submit" className="btn_text submit" onClick={handleSubmit}>
        send recovery email
      </button>
    </form>
  );
}
