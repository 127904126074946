import { useState } from "react";

import ScreenHolder from "../../../../Partials/Layouts/ScreenHolder/ScreenHolder";

import SentEmailScreen from "./components/SentEmailScreen";
import RecoverPassScreen from "./components/recoverPassScreen/RecoverPassScreen";

import "../../Auth.css";

function RecoverPassOne() {
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState("");

  return (
    <ScreenHolder>
      {emailSent ? (
        <SentEmailScreen email={email} setEmailSent={setEmailSent} />
      ) : (
        <RecoverPassScreen
          email={email}
          setEmail={setEmail}
          setEmailSent={setEmailSent}
        />
      )}
    </ScreenHolder>
  );
}

export default RecoverPassOne;
