import { useContext, useEffect, useState } from "react";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import AppContext from "../../../../../AppContext/AppContext";
import { ALL_EMPLOYESS_API } from "../../../../../Utilities/APIs/APIs";

export default function useFetchEmployees({ filter, toggleFetch }) {
  const [employees, setEmployees] = useState(null);
  const { employee, setIsLoading } = useContext(AppContext);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetEmployees() {
      setIsLoading(true);
      const { data } = await axiosInstance.get(
        `${ALL_EMPLOYESS_API}?filter=${filter}`
      );
      setEmployees(data);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }

    fetchAndSetEmployees();
  }, [employee.token, toggleFetch, filter]);

  return employees;
}
