import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { STREET_API } from "../../../../Utilities/APIs/APIs";



function ViewStreet  ({targetID})  {
    const [streetInfo,setStreetInfo] = useState(null)
    const axiosInstance = useAxiosInstance();

    useEffect(() => {
      async function fetchAndSetstreetInfo() {
        const { data } = await axiosInstance.get(STREET_API + targetID);
        setStreetInfo(data);
      }
      fetchAndSetstreetInfo();
    }, [targetID, axiosInstance,]);
    return (
        <div className="crud_view_content">
           
          <h1>Street</h1>
          <p>{streetInfo?.name}</p>   
   
        </div>
    );
};

export default ViewStreet;