import React from "react";
import CRUDTable from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ViewButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import CRUDButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";
import { RiBankCardLine, RiStore2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

export default function SellerTable({
  sellers,
  setShowModal,
  setTargetID,
  setShowViewSection,
}) {
  const navigate = useNavigate();

  return (
    <CRUDTable>
      <CRUDTableHeader>
        <CRUDth th="Seller Name" />
        <CRUDth th="Email" />
        <CRUDth th="Actions" />
      </CRUDTableHeader>
      <tbody>
        {sellers?.map((seller) => (
          <CRUDTableRow key={seller._id}>
            <ShortTextCell text={seller.name} />

            <ShortTextCell text={seller.email} />
            <td className="action_button_cell">
              <ViewButton
                setShowModal={setShowModal}
                setShowViewSection={setShowViewSection}
                targetID={seller._id}
                setTargetID={setTargetID}
              />
              <CRUDButton
                dataTip="Stores"
                dataFor="Stores Item"
                handleClick={() => navigate(`/seller/store/${seller._id}`)}
              >
                <RiStore2Line />
              </CRUDButton>

              <CRUDButton
                dataTip="Payments"
                dataFor="Payments"
                handleClick={() => navigate(`/seller/payments/${seller._id}`)}
              >
                <RiBankCardLine />
              </CRUDButton>
            </td>
          </CRUDTableRow>
        ))}
      </tbody>
    </CRUDTable>
  );
}
