import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function TotalCustomer({customers}) {

  //  const [totalCustomers,setTotalCustomers] = useState("")
  //  const axiosInstance = useAxiosInstance()
  //  useEffect(() => {
  //        async function fetchAndSetTotalCustomer() {  
  //          const { data } = await axiosInstance.get("customer");
  //          setTotalCustomers(data)
  //        }
  //        fetchAndSetTotalCustomer();
  //      }, [axiosInstance]);
 
      //  console.log("total customer is",totalCustomers)
    return (
      <div className="card">
          <p>Total Customer:</p>
           <h3>{customers}</h3>
      </div>
  
    )
  }
  
  export default TotalCustomer

