import { useContext, useEffect, useState } from "react";
import { CUSTOMER_API } from "../../../../../Utilities/APIs/APIs";
import AppContext from "../../../../../AppContext/AppContext";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";

export default function useFetchCustomers() {
  const [customers, setCustomers] = useState([]);
  const { setIsLoading } = useContext(AppContext);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetCustomers() {
      setIsLoading(true);
      const { data } = await axiosInstance.get(CUSTOMER_API);

      setCustomers(data);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
    fetchAndSetCustomers();
  }, []);

  return customers;
}
