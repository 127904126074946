import React from "react";

export default function UserDetails({ employee }) {
  return (
    <div className="user_details">
      <h3>{employee.name}</h3>
      <p>{employee.email}</p>
    </div>
  );
}
