import CreateButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import { useNavigate } from "react-router-dom";

export default function SharedProductHeader({
  setShowCreateForm,
  setShowModal,
  type,
}) {
  const navigate = useNavigate();
  return (
    <div className="screen_header">
      <button
        className="btn btn-primary"
        onClick={() => navigate(`/product/size/${type}`)}
      >
        Manage Size
      </button>
      <button
        className="btn btn-primary"
        onClick={() => navigate(`/product/color/${type}`)}
      >
        Manage Color
      </button>
      <CreateButton
        screenTopicSingular={type}
        setShowCreateForm={setShowCreateForm}
        setShowModal={setShowModal}
      />
    </div>
  );
}
