import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
// import {  RiSearchLine } from "react-icons/ri";
import AppContext from "../../../../../AppContext/AppContext";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import { COLOUR_API } from "../../../../../Utilities/APIs/APIs";
import CreateButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ViewButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import EditButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
// import DeleteButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";
import Modal from "../../../../Partials/Elements/Modal/Modal";
import CreateColor from "./CreateColor";
import UpdateColor from "./UpdateColor";
// import DeleteItem from "../../../../Partials/Layouts/DeleteItem/DeleteItem";
import ViewColor from "./ViewColor";
import ArchiveButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ArchiveButton/ArchiveButton";
import ArchiveItem from "../../../../Partials/Layouts/ArchiveItem/ArchiveItem";
import SelectInput from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";

function ProductColor() {
  // const { storeId } = useParams();
  const { storeId, type } = useParams();

  // const [search, setSearch] = useState("");
  const [colors, setColors] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const { seller } = useContext(AppContext);
  const [archive, setArchive] = useState(null);
  const [filter, setFilter] = useState("all");

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetColors() {
      const { data } = await axiosInstance.get(
        `${COLOUR_API}getAllColorByType/${type}`
      );
      setColors(data);
    }
    fetchAndSetColors();
  }, [axiosInstance, toggleFetch, storeId, type]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  const colorName = type === "sneaker" ? "Colorway" : "Color";

  return (
    <>
      <div className="crud_holder">
        <div className="screen_header">
          <h1 className="screen_heading">
            {colorName} ({colors?.length} in total)
          </h1>
          {/* <div>
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="input_field"
              placeholder="Search"
              style={{ width: "400px" }}
            />
            <RiSearchLine
              style={{
                width: "30px",
                height: "30px",
                marginLeft: "-50px",
                color: "var(--white-2)",
              }}
            />
          </div> */}

          <SelectInput value={filter} setState={setFilter}>
            <option value={"all"}>All</option>
            <option value={"archive"}>Archive</option>
            <option value={"active"}>Active</option>
          </SelectInput>

          <CreateButton
            screenTopicSingular={"Colorway"}
            setShowCreateForm={setShowCreateForm}
            setShowModal={setShowModal}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Name" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {colors?.map((color) => (
              <CRUDTableRow key={color._id}>
                <ShortTextCell text={color.name} />
                <td className="action_button_cell">
                  <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={color._id}
                    setTargetID={setTargetID}
                  />
                  <EditButton
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={color._id}
                    setTargetID={setTargetID}
                  />

                  <ArchiveButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={color._id}
                    isArchive={color.isArchive}
                    setTargetID={setTargetID}
                    setArchive={setArchive}
                  />
                  {/* <DeleteButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={color._id}
                    setTargetID={setTargetID}
                  /> */}
                  {/* <CRUDButton
                    handleClick={() => navigate("/sizeAsset/" + size._id)}
                  >
                    <RiImage2Line />
                  </CRUDButton> */}
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>

      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Color`) ||
            (showUpdateForm && `Update Color `) ||
            (showDeleteSection && `Archive Color`)
          }
        >
          {showCreateForm && (
            <CreateColor
              type={type}
              seller={seller}
              storeId={storeId}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateColor
              seller={seller}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
            // <DeleteItem
            //   api={COLOUR_API}
            //   targetID={targetID}
            //   setShowModal={setShowModal}
            //   setShowDeleteSection={setShowDeleteSection}
            //   triggerFetch={triggerFetch}
            // />

            <ArchiveItem
              api={COLOUR_API + "archiveColor/"}
              targetID={targetID}
              seller={seller}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
              isArchive={archive}
            />
          )}
        </Modal>
      )}

      {showModal && showViewSection && (
        <Modal
          handleClose={handleClose}
          modalHeading={showViewSection && `View Color `}
        >
          <ViewColor targetID={targetID} seller={seller} />
        </Modal>
      )}
    </>
  );
}

export default ProductColor;
