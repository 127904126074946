import { useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import { STREET_API } from "../../../../Utilities/APIs/APIs";


function CreateStreet({ setShowCreateForm, setShowModal, triggerFetch,cityId }) {
  const axiosInstance = useAxiosInstance()
  const [name, setState] = useState("");
  async function handleSubmit(e) {
    e.preventDefault();
    const itemData = new FormData();

    itemData.append("name",name)
    itemData.append("city",cityId)

    const response = await axiosInstance.post(STREET_API, itemData)
    if (response.data) {
        setShowCreateForm(false);
        setShowModal(false);
        triggerFetch()
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`State`}
        value={name}
        placeholder={`Enter State`}
        setState={setState}
      />
     
      <FormSubmitButton text="Create State" />
    </Form>
  );
}

export default CreateStreet;
