import { useContext, useState } from "react";
import AppContext from "../../../../AppContext/AppContext";
import NavbarLogo from "./components/NavbarLogo/NavbarLogo";
import UserImage from "./components/UserImage";
import UserDetails from "./components/UserDetails";
import DropdownButton from "./components/DropdownButton";
import DropDrownItems from "./components/DropDrownItems";

import "./NavBar.css";
function NavBar() {
  const { employee } = useContext(AppContext);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav>
      <div className="nav_container">
        <NavbarLogo />

        {employee && (
          <div className="nav_user_wrapper">
            <UserImage employee={employee} />
            <UserDetails employee={employee} />
            <DropdownButton isOpen={isOpen} setIsOpen={setIsOpen} />
            <DropDrownItems isOpen={isOpen} />
          </div>
        )}
      </div>
    </nav>
  );
}

export default NavBar;
