import { useState, useContext, useEffect } from "react";
import { RiSearchLine } from "react-icons/ri";
import { useParams } from "react-router-dom";

import DeleteItem from "../../../Partials/Layouts/DeleteItem/DeleteItem";
import AppContext from "../../../../AppContext/AppContext";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import EditButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";
import Modal from "../../../Partials/Elements/Modal/Modal";

import "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDBoilerPlate.css";

import "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDBoilerPlate.css";
import "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CRUDHeader.css";
import "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ActionButtonsCell/ActionButtonsCell.css";
import "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDViewBoilerPlate/CRUDViewBoilerPlate.css";
import CreateStreet from "./CreateStreet";
import UpdateStreet from "./UpdateStreet";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import ViewStreet from "./ViewStreet";
import { STREET_API } from "../../../../Utilities/APIs/APIs";

function Street() {
  const { cityId } = useParams();
  const [showViewSection, setShowViewSection] = useState("");
  const [search, setSearch] = useState("");
  const [streets, setStreets] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const { employee } = useContext(AppContext);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetStreets() {
      const { data } = await axiosInstance.get(
        STREET_API + "streetByCity/" + cityId
      );
      setStreets(data);
    }
    fetchAndSetStreets();
  }, [axiosInstance, toggleFetch, cityId]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <h1 className="heading">Street ({streets?.length} in total)</h1>
          {/* <div>
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="input_field"
              placeholder="Search"
              style={{ width: "400px" }}
            />
            <RiSearchLine
              style={{
                width: "30px",
                height: "30px",
                marginLeft: "-50px",
                marginBottom: "20px",
                color: "var(--white-2)",
              }}
            />
          </div> */}

          <CreateButton
            screenTopicSingular={"Street"}
            setShowCreateForm={setShowCreateForm}
            setShowModal={setShowModal}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Street" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {streets &&
              streets?.map((streets) => (
                <CRUDTableRow key={streets._id}>
                  <ShortTextCell text={streets.name} />

                  <td className="action_button_cell">
                    <ViewButton
                      setShowModal={setShowModal}
                      setShowViewSection={setShowViewSection}
                      targetID={streets._id}
                      setTargetID={setTargetID}
                    />
                    <EditButton
                      setShowModal={setShowModal}
                      setShowUpdateForm={setShowUpdateForm}
                      targetID={streets._id}
                      setTargetID={setTargetID}
                    />
                    <DeleteButton
                      setShowModal={setShowModal}
                      setShowDeleteSection={setShowDeleteSection}
                      targetID={streets._id}
                      setTargetID={setTargetID}
                    />
                  </td>
                </CRUDTableRow>
              ))}
          </tbody>
        </CRUDTable>
      </div>

      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Street`) ||
            (showUpdateForm && `Update Street `) ||
            (showDeleteSection && `Delete Street`)
          }
        >
          {showCreateForm && (
            <CreateStreet
              employee={employee}
              cityId={cityId}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateStreet
              employee={employee}
              cityId={cityId}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
            <DeleteItem
              api={STREET_API}
              targetID={targetID}
              employee={employee}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}

      {showModal && showViewSection && (
        <Modal
          handleClose={handleClose}
          modalHeading={showViewSection && `View Street Slider`}
          triggerFetch={triggerFetch}
        >
          <ViewStreet targetID={targetID} employee={employee} />
        </Modal>
      )}
    </>
  );
}

export default Street;
