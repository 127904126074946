export const IMAGE_URL = process.env.REACT_APP_SPACES_URL;

// employee
export const ALL_EMPLOYESS_API = "getAllEmployees/";

// Invite employess
export const ALL_EMPLOYEE_INVITES_API = "employeeinvite/getAllInvites/";

// customers
export const CUSTOMER_API = "customer/";

// sellers
export const SELLER_API = "seller/";
export const ALL_SELLER_API = SELLER_API + "getAllSeller/";
export const SINGLE_SELLER_API = SELLER_API + "getSingleSeller/";
export const SELLER_ALL_STORE = SELLER_API + "getSellerAllstore/";
export const SELLER_SINGLE_STORE = SELLER_API + "getSellerSinglestore/";
export const SINGLE_STORE_PRODUCT = SELLER_API + "getSingleStoreProduct/";
export const SINGLE_PRODUCT_VIEW_API = SELLER_API + "getSingleProduct/";

// address api
export const STATE_API = "/address/state/";
export const CITY_API = "/address/state/city/";
export const STREET_API = "/address/state/city/street/";

export const ORDERS_API = SELLER_API + "orders/";

export const COLOUR_API = "color/";
export const SIZE_API = "size/";
export const Product_API = "product/";
export const SINGLE_PRODUCT_API = Product_API + "getSingleProduct/";
export const PRODUCT_ALL_IMAGE = Product_API + "productImages/";

export const PAYMENT_TO_SELLER = "paymentToSeller/";
