import { useEffect, useState } from "react";
import Image from "../../../Partials/Elements/Image/Image";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import {  SINGLE_PRODUCT_VIEW_API } from "../../../../Utilities/APIs/APIs";

function ViewProduct({ targetID}) {
  const [productInfo, setproductInfo] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndsetProductInfo() {
      const { data } = await axiosInstance.get(SINGLE_PRODUCT_VIEW_API + targetID);
      setproductInfo(data);
    }
    fetchAndsetProductInfo();
  }, [targetID, axiosInstance]);

  return (
    <div className="crud_view_content">

      { productInfo && (
        <>
          <h1>Product Name</h1>
          <p>{productInfo?.name}</p>

          <h1>Sku</h1>
          <p>{productInfo.sku}</p>

          <h1>Colorway</h1>
          <p>{productInfo.colorway}</p>

          <h1>Release Date</h1>
          <p>{productInfo.releaseDate}</p>

          <h1>Retial Cost</h1>
          <p>{productInfo.retailCost}</p>

          <h1>Condition</h1>
          <p>{productInfo.condition}</p>

          <h1>Slug</h1>
          <p>{productInfo.slug}</p>

          <h1>Product Image</h1>
          <Image imgLink={productInfo.images && productInfo.images[0]} imgAlt={"Card Front Image"} />
        </>
      )}
    </div>
  );
}

export default ViewProduct;
