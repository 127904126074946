import  {  useEffect, useState } from "react";

import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../Partials/Layouts/Forms/Form";
import { STATE_API } from "../../../Utilities/APIs/APIs";

function UdpateAddress({ setShowUpdateForm, setShowModal, triggerFetch,targetID }) {
  const axiosInstance = useAxiosInstance()
  const [name, setName] = useState("");

  useEffect(() => {
    async function fetchAndsetStoreInfo() {
      const { data } = await axiosInstance.get(STATE_API + targetID);
      setName(data.name);   
    }
    fetchAndsetStoreInfo();
  }, [targetID, axiosInstance,]);

  async function handleSubmit(e) {
    e.preventDefault();
    const itemData = new FormData();

    itemData.append("name",name)

    const response = await axiosInstance.patch(STATE_API + targetID, itemData)
    if (response.data) {
        setShowUpdateForm(false);
        setShowModal(false);
        triggerFetch()
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`State`}
        value={name}
        placeholder={`Enter State`}
        setState={setName}
      />
     
      <FormSubmitButton text="Update State" />
    </Form>
  );
}

export default UdpateAddress;
