import { useState } from "react";

import useFetchCustomers from "./Hooks/useFetchCustomers";
import CustomerHeader from "./components/CustomerHeader";
import CustomerTable from "./components/CustomerTable";
import CustomerModal from "./components/CustomerModal/CustomerModal";

function Customers() {
  const customers = useFetchCustomers();

  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);

  function handleClose() {
    setShowModal(false);
    setShowViewSection(false);
  }

  return (
    <>
      <div className="crud_holder">
        <CustomerHeader customers={customers} />
        <CustomerTable
          customers={customers}
          setShowModal={setShowModal}
          setTargetID={setTargetID}
          setShowViewSection={setShowViewSection}
        />
      </div>
      <CustomerModal
        showModal={showModal}
        handleClose={handleClose}
        targetID={targetID}
        showViewSection={showViewSection}
      />
    </>
  );
}

export default Customers;
