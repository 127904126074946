import {  useParams } from "react-router-dom"
import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import CRUDTable from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ImageCell from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell";
import ViewButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import Modal from "../../../../Partials/Elements/Modal/Modal";
import ViewItems from "../../../SellerStore/Order/OrderItems/ViewItems/ViewItems";




function CustomerOrderItems() {
  const { orderId } = useParams();
  const [orderItems, setOrderItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
 
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndsetOrderItems() {
      const { data } = await axiosInstance.get( "/customer/orderItems/" + orderId);
      setOrderItems(data);
    }
    fetchAndsetOrderItems();
  }, [axiosInstance,orderId]);


  function handleClose() {
    setShowModal(false);
  }
  return (
    <>
      <div className="crud_holder">
        <div className="screen_header">
          <h1 className="screen_heading">Order Items ({orderItems?.length} in total)</h1>
          {/* <div>
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="input_field"
              placeholder="Search"
              style={{ width: "400px" }}
            />
            <RiSearchLine
              style={{
                width: "30px",
                height: "30px",
                marginLeft: "-50px",
                color: "var(--white-2)",
              }}
            />
          </div> */}
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Product Name" />
            <CRUDth th="Quantity" />
            <CRUDth th="Price" />
            <CRUDth th="Size" />
            <CRUDth th="Image" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {orderItems && orderItems?.map((item) => (
              <CRUDTableRow key={item._id}>
                <ShortTextCell text={item.item.name} />
                <ShortTextCell text={item.quantity} />
                <ShortTextCell text={item.total} />
                <ShortTextCell text={item.size.name} />
                {/* <ImageCell imgSrc={item.item.images && item.item.images[0]} imgAlt={"Product Image"}/> */}
                <ImageCell imgSrc={item.item.cardImage} imgAlt={"Product Image"}/>

                <td className="action_button_cell">
                  <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={item._id}
                    setTargetID={setTargetID}
                  />
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>

      {showModal && showViewSection && (
        <Modal
         
          handleClose={handleClose}
          modalHeading={showViewSection && `View Order Item Details`}
        >
          <ViewItems
          targetID={targetID} 
           />
        </Modal>
      )}
    </>
  );
}

export default CustomerOrderItems;
