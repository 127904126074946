import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
// import { RiSearchLine } from 'react-icons/ri'

import CreateSize from "./CreateSize";
import UpdateSize from "./UpdateSize";
// import DeleteItem from '../../../../Partials/Layouts/DeleteItem/DeleteItem'
import ViewSize from "./ViewSize";
import AppContext from "../../../../../AppContext/AppContext";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import CreateButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ViewButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import EditButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
// import DeleteButton from '../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton'
import Modal from "../../../../Partials/Elements/Modal/Modal";
import { SIZE_API } from "../../../../../Utilities/APIs/APIs";
import ArchiveButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ArchiveButton/ArchiveButton";
import ArchiveItem from "../../../../Partials/Layouts/ArchiveItem/ArchiveItem";
import SelectInput from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";

function ProductSize() {
  const { storeId, type } = useParams();

  // const [search, setSearch] = useState('')
  const [sizes, setSizes] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const [archive, setArchive] = useState(null);
  const [filter, setFilter] = useState("all");

  const { seller } = useContext(AppContext);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetSizes() {
      const { data } = await axiosInstance.get(
        `${SIZE_API}getAllSizeByType/${type}`
      );
      setSizes(data);
    }
    fetchAndSetSizes();
  }, [axiosInstance, toggleFetch, storeId, filter, type]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <div className="screen_header">
          <h1 className="screen_heading">Size ({sizes?.length} in total)</h1>
          {/* <SelectInput value={filter} setState={setFilter}>
            <option value={"all"}>All</option>
            <option value={"archive"}>Archive</option>
            <option value={"active"}>Active</option>
          </SelectInput> */}

          <CreateButton
            screenTopicSingular={"Size"}
            setShowCreateForm={setShowCreateForm}
            setShowModal={setShowModal}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Name" />
            <CRUDth th="Gender" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {sizes?.map((size) => (
              <CRUDTableRow key={size._id}>
                <ShortTextCell text={size.name} />
                <ShortTextCell text={size.gender} />

                <td className="action_button_cell">
                  <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={size._id}
                    setTargetID={setTargetID}
                  />
                  <EditButton
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={size._id}
                    setTargetID={setTargetID}
                  />
                  <ArchiveButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={size._id}
                    isArchive={size.isArchive}
                    setTargetID={setTargetID}
                    setArchive={setArchive}
                  />
                  {/* <DeleteButton
                        setShowModal={setShowModal}
                        setShowDeleteSection={setShowDeleteSection}
                        targetID={size._id}
                        setTargetID={setTargetID}
                      /> */}
                  {/* <CRUDButton
                    handleClick={() => navigate("/sizeAsset/" + size._id)}
                  >
                    <RiImage2Line />
                  </CRUDButton> */}
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>

      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Size`) ||
            (showUpdateForm && `Update Size `) ||
            (showDeleteSection && `Archive Size`)
          }
        >
          {showCreateForm && (
            <CreateSize
              type={type}
              seller={seller}
              storeId={storeId}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateSize
              seller={seller}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
            <ArchiveItem
              api={SIZE_API + "archiveSize/"}
              targetID={targetID}
              seller={seller}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
              isArchive={archive}
            />
            // <DeleteItem
            //   api={SIZE_API + 'deleteSize/'}
            //   targetID={targetID}
            //   setShowModal={setShowModal}
            //   setShowDeleteSection={setShowDeleteSection}
            //   triggerFetch={triggerFetch}
            // />
          )}
        </Modal>
      )}

      {showModal && showViewSection && (
        <Modal
          handleClose={handleClose}
          modalHeading={showViewSection && `View Size`}
        >
          <ViewSize targetID={targetID} seller={seller} />
        </Modal>
      )}
    </>
  );
}

export default ProductSize;
