import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../../../Utilities/Hooks/useAxiosInstance";

export default function useFetchColorway({ type }) {
  const [colorways, setColorways] = useState([]);
  const axiosInstance = useAxiosInstance();

  async function fetchColorways() {
    const { data } = await axiosInstance.get(
      `/color/getAllColorByType/${type}`
    );
    const colors = data.map((color) => {
      return {
        value: color._id,
        label: color.name,
      };
    });
    setColorways(colors);
  }

  useEffect(() => {
    fetchColorways();
  }, [type]);

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length >= 2) {
      try {
        const { data } = await axiosInstance.get(
          `/color/getAllColorByType/${type}?search=${inputValue}`
        );
        const newOptions = data.map((product) => ({
          label: product.name,
          value: product._id,
        }));
        setColorways(newOptions);
        callback(newOptions);
      } catch (err) {
        console.error(err);
        callback([]);
      }
    } else {
      fetchColorways();
    }
  };

  return { colorways, loadOptions };
}
