import ScreenHolder from "../../../Partials/Layouts/ScreenHolder/ScreenHolder";
import LoginHeader from "./components/LoginHeader";
import LoginForm from "./components/LoginForm";
import ResetPasswordLink from "./components/ResetPasswordLink";

import "../Auth.css";

function Login() {
  return (
    <ScreenHolder>
      <div className="form_wrapper">
        <LoginHeader />
        <LoginForm />
        <ResetPasswordLink />
      </div>
    </ScreenHolder>
  );
}

export default Login;
