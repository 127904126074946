import React, { useEffect, useState } from "react";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";

import TotalOrderValue from "./TotalOrderValue/TotalOrderValue";
import TotalPayableValue from "./TotalPayableValue/TotalPayableValue";
import TotalPaidValue from "./TotalPaidValue/TotalPaidValue";
import TotalSeller from "./TotalSeller/TotalSeller";
import TotalCustomer from "./TotalCustomer/TotalCustomer";
import TotalStore from "./TotalStore/TotalStore";

import "./Dashboard.css";

function Dashboard() {
  const [sellers, setSellers] = useState("");
  const [customers, setCustomers] = useState("");
  const [stores, setStores] = useState("");
  const [totalOrderValue, setTotalOrderValue] = useState("");
  const [totalPaymentValue, setTotalPaymentValue] = useState("");

  const axiosInstance = useAxiosInstance();
  useEffect(() => {
    async function fetchAndSetSellers() {
      const { data } = await axiosInstance.get("dashboardInfo");
      setSellers(data.sellerCount);
      setCustomers(data.customerCount);
      setStores(data.storeCount);
      setTotalOrderValue(data.totalOrderValue);
      setTotalPaymentValue(data.totalPaymentToSeller);
    }
    fetchAndSetSellers();
  }, []);

  return (
    <div className="dashboard_crud_holder">
      <div className="dashboard">
        <TotalOrderValue totalOrderValue={totalOrderValue} />
        <TotalPaidValue totalPaymentValue={totalPaymentValue} />
        <TotalPayableValue
          totalOrderValue={totalOrderValue}
          totalPaymentValue={totalPaymentValue}
        />
        <TotalSeller sellers={sellers} />
        <TotalCustomer customers={customers} />
        <TotalStore stores={stores} />
      </div>
    </div>
  );
}

export default Dashboard;
