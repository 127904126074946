import SmallSideBarItem from "./SmallSideBarItem/SmallSideBarItem";

import {
  RiBankCardLine,
  RiGift2Line,
  RiGroupLine,
  RiHome2Line,
  RiMapPinLine,
} from "react-icons/ri";

import "./SideBar.css";
import { GiClothes, GiConverseShoe } from "react-icons/gi";

function SideBar() {
  return (
    <>
      <section className="small_sidebar">
        <div className="container">
          <SmallSideBarItem link="/" dataTip="Home Page" dataFor="dashboard">
            <RiHome2Line />
          </SmallSideBarItem>

          <SmallSideBarItem link="/usersScreen" dataTip="Users" dataFor="Users">
            <RiGroupLine />
          </SmallSideBarItem>

          <SmallSideBarItem
            link="/paymentToSeller"
            dataTip="Payment To Seller"
            dataFor="Payment To Seller"
          >
            <RiBankCardLine />
          </SmallSideBarItem>

          <SmallSideBarItem
            link="/productScreen/sneaker"
            dataTip="Sneaker"
            dataFor="Sneaker"
          >
            <GiConverseShoe size={20} color="#f9bd34" />
          </SmallSideBarItem>

          <SmallSideBarItem
            link="/productScreen/apparel"
            dataTip="Apparel"
            dataFor="Apparel"
          >
            <GiClothes size={20} color="#f9bd34" />
          </SmallSideBarItem>

          <SmallSideBarItem
            link="/address"
            dataTip="Location"
            dataFor="Location"
          >
            <RiMapPinLine />
          </SmallSideBarItem>
        </div>
      </section>
    </>
  );
}

export default SideBar;
