import React from "react";
import Image from "../../../Elements/Image/Image";

export default function UserImage({ employee }) {
  return (
    <div className="user_wrapper">
      <Image imgLink={employee.dp} imgAlt="employee-img" />
    </div>
  );
}
